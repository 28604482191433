div.dropdown-menu {
  max-height: 20.5rem;
  overflow-y: scroll;
}

td.numcell, th.numcell {
  text-align: right;
}

div.chart svg {
    max-width: 400px;
    height: 400px;
    margin: 0 auto;
}

div.chart-wide svg {
    max-width: 600px;
    height: 400px;
    margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 { padding-top: 8px; }

.btn {
  text-transform: none;
}

.btn-link {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen {
  .container-fluid {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

@media print {
  @page { size: auto }
  body {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    font-size: 80%
  }

  a {
    text-decoration: none !important;
    color: inherit;
  }

  div.sub-nav { display: none; }

  abbr[title] { border-bottom: none !important; }

  h1, h2, h3, h4 { page-break-after: avoid; }
  .dont-break { page-break-inside: avoid;}
  .break-before { page-break-before: always }

  table.table tfoot { display: table-row-group }
}